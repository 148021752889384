@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define the font */
@font-face {
  font-family: 'Fredoka';
  src: url('https://fonts.googleapis.com/css2?family=Fredoka:wght@700&display=swap" rel="stylesheet');
}

/* Apply the Fredoka font to the entire website */
html {
  font-family: 'Fredoka', sans-serif;
  scroll-behavior: smooth;
}

/* body { */
  /* margin: 0; */
  /* background: #a1d683; Fallback color */
  /* background: linear-gradient(90deg,100%, #a1d683); /* Gradient */

  body {
    margin: 0;
    background: #a1d683; /* Fallback color */
    /* background: linear-gradient(90deg, #140a25 20%, #24123b); Gradient */
    background: linear-gradient(90deg, #E07A63 10%, #FFC051); /* Gradient */

  }

.toggled #line {
  transform: translateX(-0.45rem) translateY(0.25rem) rotate(90deg);
}

.toggled #line2 {
  transform: translateY(-1rem) scaleX(80%) translateX(0.2em);
}

.toggled #line3 {
  transform: translateY(-0.0rem) translateX(0.19rem) scaleX(80%) rotate(0deg);
}

.btn {
  border: 1px solid #8f76af;
  border-radius: 9999px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn:hover {
  background-color: #348478;
}

.link {
  color: white;
}

.link:hover {
  color: #348478;
  /* color: #823266; */
  
}

.btn-connect-container {
  border: 1px solid #8f76af;
  border-radius: 9999px;
}
